<template>
  <div class="popup-successful-add-object">
    <div class="popup-successful-add-object__wrapper">
      <Column class="grid-md-6 grid-lg-6"
              align="center"
              justify="center">
        <Row>
          <Icon class="popup-successful-add-object__icon"
                xlink="successfulObject"
                viewport="0 0 105 96"/>
        </Row>
        <p class="popup-successful-add-object__title">
          Благодарим за добавление<br/></p>
        <p class="popup-successful-add-object__description">После проверки модераторов ваш
          объект<br/> появится на нашем портале.</p>
        <div>
          <Button v-on:click.native="onMain"
                  class="popup-successful-add-object__button"
                  color="transparent">на главную
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupSuccessfulAddObject',
  methods: {
    onMain() {
      this.$store.commit('CLOSE_POPUP');
      if (this.$route.path !== '/') this.$router.push('/');
    },
  },
};
</script>
